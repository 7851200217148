// @ts-nocheck
/* eslint-disable */
module.exports = {
  languageData: {
  "plurals": function (n, ord) {
    var s = String(n).split("."),
        v0 = !s[1],
        t0 = Number(s[0]) == n,
        n10 = t0 && s[0].slice(-1),
        n100 = t0 && s[0].slice(-2);
    if (ord) return n10 == 1 && n100 != 11 ? "one" : n10 == 2 && n100 != 12 ? "two" : n10 == 3 && n100 != 13 ? "few" : "other";
    return n == 1 && v0 ? "one" : "other";
  }
},
  messages: {
    "A PDF of your form is attached to this email. Please save a copy for your records.": "A PDF of your form is attached to this email. Please save a copy for your records.",
    "A copy of the declaration has also been sent to your local court via email in order to ensure they have it on record if your landlord attempts to initiate an eviction case.": "A copy of the declaration has also been sent to your local court via email in order to ensure they have it on record if your landlord attempts to initiate an eviction case.",
    "A hard copy of your form has also been mailed to your landlord via USPS mail. You can also track the delivery of your hard copy form using USPS Tracking:": "A hard copy of your form has also been mailed to your landlord via USPS mail. You can also track the delivery of your hard copy form using USPS Tracking:",
    "Address:": "Address:",
    "After sending your hardship declaration form, connect with local organizing groups to get involved in the fight to make New York eviction free!": "After sending your hardship declaration form, connect with local organizing groups to get involved in the fight to make New York eviction free!",
    "Agree to the state’s legal terms": "Agree to the state\u2019s legal terms",
    "Already submitted a hardship declaration form?<0/><1>Log in here to download your copy</1>": "Already submitted a hardship declaration form?<0/><1>Log in here to download your copy</1>",
    "Any questions?": "Any questions?",
    "Apply for ERAP Online": "Apply for ERAP Online",
    "Automatically fill in your landlord's information based on your address if you live in New York City": "Automatically fill in your landlord's information based on your address if you live in New York City",
    "Available in English and Spanish.": "Available in English and Spanish.",
    "Before you send your hardship declaration form, let's review what will be sent to make sure all the information is correct.": "Before you send your hardship declaration form, let's review what will be sent to make sure all the information is correct.",
    "Bronx Housing Court:": "Bronx Housing Court:",
    "Brooklyn Housing Court:": "Brooklyn Housing Court:",
    "Build Tenant Power": "Build Tenant Power",
    "Call the Housing Court Answers hotline at <0>212-962-4795</0>.": "Call the Housing Court Answers hotline at <0>212-962-4795</0>.",
    "Can I see what forms I’m sending before I fill them out?": "Can I see what forms I\u2019m sending before I fill them out?",
    "Can my landlord challenge my hardship declaration?": "Can my landlord challenge my hardship declaration?",
    "Check any or all that apply. Note: You <0>must select at least one box</0> in order to qualify for the State's eviction protections.": "Check any or all that apply. Note: You <0>must select at least one box</0> in order to qualify for the State's eviction protections.",
    "Check your email for a message containing a copy of your declaration and additional important information on next steps.": "Check your email for a message containing a copy of your declaration and additional important information on next steps.",
    "Continue to the confirmation page for information about the declaration you sent and next steps you can take.": "Continue to the confirmation page for information about the declaration you sent and next steps you can take.",
    "Details about your declaration": "Details about your declaration",
    "Do I have to go to the post office to mail my declaration?": "Do I have to go to the post office to mail my declaration?",
    "Do you have a current eviction court case?": "Do you have a current eviction court case?",
    "Download completed declaration": "Download completed declaration",
    "Due to the COVID-19 pandemic, some offices are closed and may not answer phones.": "Due to the COVID-19 pandemic, some offices are closed and may not answer phones.",
    "Email:": "Email:",
    "Eviction Free NY has been suspended": "Eviction Free NY has been suspended",
    "Fill out my form": "Fill out my form",
    "Fill out your hardship declaration form online": "Fill out your hardship declaration form online",
    "Find help": "Find help",
    "For New York State tenants": "For New York State tenants",
    "For more information about New York’s eviction protections and your rights as a tenant, check out our FAQ on the <0>Right to Counsel website</0>.": "For more information about New York\u2019s eviction protections and your rights as a tenant, check out our FAQ on the <0>Right to Counsel website</0>.",
    "For tenants by tenants": "For tenants by tenants",
    "Hello {0},": function (a) {
  return ["Hello ", a("0"), ","];
},
    "Highly recommended.": "Highly recommended.",
    "Hours of operation: Monday to Friday, 9am - 5pm.": "Hours of operation: Monday to Friday, 9am - 5pm.",
    "I agree to the <0>Eviction Free NY terms and conditions</0>.": "I agree to the <0>Eviction Free NY terms and conditions</0>.",
    "I am experiencing financial hardship due to COVID-19.": "I am experiencing financial hardship due to COVID-19.",
    "I have a current eviction case in NYC. How do I connect with a lawyer?": "I have a current eviction case in NYC. How do I connect with a lawyer?",
    "I live in another state that isn’t New York. Is this tool for me?": "I live in another state that isn\u2019t New York. Is this tool for me?",
    "I understand I am signing and submitting this form under penalty of law. I know it is against the law to make a statement on this form that I know is false.": "I understand I am signing and submitting this form under penalty of law. I know it is against the law to make a statement on this form that I know is false.",
    "I understand that I must comply with all other lawful terms under my tenancy, lease agreement or similar contract.": "I understand that I must comply with all other lawful terms under my tenancy, lease agreement or similar contract.",
    "If you know the court name your case is associated with, please provide it below. Otherwise, leave this blank.": "If you know the court name your case is associated with, please provide it below. Otherwise, leave this blank.",
    "Is the online tool the only way to submit this form?": "Is the online tool the only way to submit this form?",
    "Is there a way to resend the declaration if the landlord claims they never received it?": "Is there a way to resend the declaration if the landlord claims they never received it?",
    "I’m undocumented. Can I use this tool?": "I\u2019m undocumented. Can I use this tool?",
    "January 15": "January 15",
    "January 15, 2022": "January 15, 2022",
    "Join the tenant movement": "Join the tenant movement",
    "Learn more on <0/>.": "Learn more on <0/>.",
    "Let’s set you up with an account. An account will enable you to save your information, download your declaration, and more.": "Let\u2019s set you up with an account. An account will enable you to save your information, download your declaration, and more.",
    "List of groups who can help you apply for ERAP": "List of groups who can help you apply for ERAP",
    "Made by non-profits <0>Right to Counsel NYC Coalition</0>, <1>Housing Justice for All</1>, and <2>JustFix</2>": "Made by non-profits <0>Right to Counsel NYC Coalition</0>, <1>Housing Justice for All</1>, and <2>JustFix</2>",
    "Manhattan Housing Court:": "Manhattan Housing Court:",
    "Navigating these laws is confusing. Here are a few <0>frequently asked questions</0> from people who have used our tool:": "Navigating these laws is confusing. Here are a few <0>frequently asked questions</0> from people who have used our tool:",
    "Need additional support?": "Need additional support?",
    "No. Unfortunately, these protections only apply to residents of New York State.": "No. Unfortunately, these protections only apply to residents of New York State.",
    "Other important resources:": "Other important resources:",
    "Photo credits:": "Photo credits:",
    "Preview this declaration as a PDF": "Preview this declaration as a PDF",
    "Protect yourself from eviction": "Protect yourself from eviction",
    "Protect yourself from eviction in New York State": "Protect yourself from eviction in New York State",
    "Queens Housing Court:": "Queens Housing Court:",
    "Rental Assistance Application Hotline:": "Rental Assistance Application Hotline:",
    "Right to Counsel's FAQ page": "Right to Counsel's FAQ page",
    "Send": "Send",
    "Send your form by USPS Certified Mail for free to your landlord": "Send your form by USPS Certified Mail for free to your landlord",
    "Send your form by email to your landlord and the courts": "Send your form by email to your landlord and the courts",
    "Shall we send your declaration?": "Shall we send your declaration?",
    "Staten Island Housing Court:": "Staten Island Housing Court:",
    "The protections outlined by NY state law apply to you regardless of immigration status.": "The protections outlined by NY state law apply to you regardless of immigration status.",
    "To get involved in organizing and the fight to #StopEvictions and #CancelRent, follow us on Twitter at <0>@RTCNYC</0> and <1>@housing4allNY</1>.": "To get involved in organizing and the fight to #StopEvictions and #CancelRent, follow us on Twitter at <0>@RTCNYC</0> and <1>@housing4allNY</1>.",
    "USPS Certified Mail": "USPS Certified Mail",
    "Unfortunately, this tool is currently only available to individuals who live in the state of New York.": "Unfortunately, this tool is currently only available to individuals who live in the state of New York.",
    "Vacating the premises and moving into new permanent housing would pose a significant health risk due to COVID-19.": "Vacating the premises and moving into new permanent housing would pose a significant health risk due to COVID-19.",
    "View list of organizations": "View list of organizations",
    "We'll include this information in your hardship declaration form.": "We'll include this information in your hardship declaration form.",
    "We'll need to add your case's index number to your declaration.": "We'll need to add your case's index number to your declaration.",
    "We'll use this information to send your hardship declaration form via certified mail for free.": "We'll use this information to send your hardship declaration form via certified mail for free.",
    "We'll use this information to send your hardship declaration form.": "We'll use this information to send your hardship declaration form.",
    "What does “financial hardship” mean?": "What does \u201Cfinancial hardship\u201D mean?",
    "What does “significant health risk” mean?": "What does \u201Csignificant health risk\u201D mean?",
    "What is the deadline for filling out the declaration?": "What is the deadline for filling out the declaration?",
    "What is the time lag between me filling this out and when it gets sent?": "What is the time lag between me filling this out and when it gets sent?",
    "When you use our tool, you will be able to preview your filled out form before sending it. You can also view a blank copy of the Hardship Declaration form.": "When you use our tool, you will be able to preview your filled out form before sending it. You can also view a blank copy of the Hardship Declaration form.",
    "Where do I find my case's index number?": "Where do I find my case's index number?",
    "Which hardship situation applies to you?": "Which hardship situation applies to you?",
    "Who is not protected by NY's COVID-19 Tenant Protection Laws?": "Who is not protected by NY's COVID-19 Tenant Protection Laws?",
    "With this free tool, you can": "With this free tool, you can",
    "Yes, Right to Counsel NYC Coalition, Housing Justice for All, and JustFix can text me to follow up about my housing issues.": "Yes, Right to Counsel NYC Coalition, Housing Justice for All, and JustFix can text me to follow up about my housing issues.",
    "Yes, the protections outlined by New York State law apply to you regardless of immigration status.": "Yes, the protections outlined by New York State law apply to you regardless of immigration status.",
    "Yes, this is a free website created by 501(c)3 non-profit organizations.": "Yes, this is a free website created by 501(c)3 non-profit organizations.",
    "You can use this website to send a hardship declaration form to your landlord and local courts—putting your eviction case on hold until {0}": function (a) {
  return ["You can use this website to send a hardship declaration form to your landlord and local courts\u2014putting your eviction case on hold until ", a("0")];
},
    "You can use this website to send a hardship declaration form to your landlord and local courts—putting your eviction case on hold until {0}.": function (a) {
  return ["You can use this website to send a hardship declaration form to your landlord and local courts\u2014putting your eviction case on hold until ", a("0"), "."];
},
    "You don't live in New York": "You don't live in New York",
    "You haven't completed previous steps. Please <0>go back</0>.": "You haven't completed previous steps. Please <0>go back</0>.",
    "You've already sent your hardship declaration": "You've already sent your hardship declaration",
    "You've sent your hardship declaration": "You've sent your hardship declaration",
    "Your Hardship Declaration form has been emailed to:": "Your Hardship Declaration form has been emailed to:",
    "Your case's court name": "Your case's court name",
    "Your case's index number": "Your case's index number",
    "Your declaration form and important next steps": "Your declaration form and important next steps",
    "Your declaration is ready to send!": "Your declaration is ready to send!",
    "Your hardship declaration form has been sent to your landlord via {0}.": function (a) {
  return ["Your hardship declaration form has been sent to your landlord via ", a("0"), "."];
},
    "Your index number can be found at the top of Postcard or Notice of Petition that you received from housing court. <0>They look like this:</0>": "Your index number can be found at the top of Postcard or Notice of Petition that you received from housing court. <0>They look like this:</0>",
    "Your landlord": "Your landlord",
    "Your local housing court": "Your local housing court",
    "email": "email",
    "email and USPS Certified Mail": "email and USPS Certified Mail",
    "eviction free nyc, eviction free ny, hardship, declaration, declare hardship, eviction, evicted": "eviction free nyc, eviction free ny, hardship, declaration, declare hardship, eviction, evicted",
    "evictionFree.canLandlordChallengeDeclarationFaq": function (a) {
  return ["<0>YES. THIS IS NEW. Landlords now have the right to challenge the validity of a tenants\u2019 hardship declaration. To do this, landlords can file a motion with the court, stating they don\u2019t believe the tenant has the hardship they claimed in their Hardship Declaration. If this happens, the Court will then grant a hearing to determine the validity of the tenant's hardship claim and tenants will need to show proof of the hardship they claimed in their Declaration. In NYC, <1>tenants will be assigned an attorney through Right to Counsel for these hearings</1>.</0><2>If the court decides that the tenant proved their hardship claim, then their case/eviction remains paused until at least ", a("0"), ". The court will direct the parties to apply to ERAP if it seems like the tenant is eligible and they haven't yet applied.</2><3>If the court decides that the tenant is NOT experiencing hardship, then their case and eviction can move forward.</3>"];
},
    "evictionfree.aboutPageText2": function (a) {
  return ["A new State law, passed in late 2020 and extended in 2021, allows most tenants to stop their eviction case until ", a("0"), ", if they fill out a \u201CHardship Declaration\u201D form. However, this law puts the responsibility on tenants to figure out how to do that and doesn\u2019t provide easy access to exercise their rights."];
},
    "evictionfree.aboutPageText3": function (a) {
  return ["Our website helps tenants submit this hardship declaration form with peace of mind\u2014sending it out via free USPS Certified Mail and email to all of the appropriate parties (your landlord and the courts) to ensure protection. And since the law doesn\u2019t go far enough to protect folks beyond ", a("0"), ", our tool connects tenants to the larger tenant movement so we can #CancelRent."];
},
    "evictionfree.agreeToStateTermsIntro": "These last questions make sure that you understand the limits of the protection granted by this hardship declaration form, and that you answered the previous questions truthfully:",
    "evictionfree.askForEmail": "We'll use this information to email you a copy of your hardship declaration form. If possible, we\u2019ll also forward you any confirmation emails from the courts once they receive your declaration form.",
    "evictionfree.confirmationNoEmailToCourtYet": "A copy of the declaration will also be sent to your local court via email\u2014we are determining the appropriate court to receive your declaration. We will notify you via text and on this page when it is sent.",
    "evictionfree.contactHcaBlurb": "If you have received a Notice to Pay Rent or Quit or any other kind of eviction notice, contact Housing Court Answers (NYC) at 212-962-4795, Monday - Friday, 9am-5pm or the Statewide Hotline at 833-503-0447, open 24/7.",
    "evictionfree.deadlineFaq1": function (a) {
  return ["You currently can submit your declaration form at any time between now and ", a("0"), ". Once you submit your declaration form via this tool, we will mail and/or email it immediately to your landlord and the courts. If you\u2019re ONLY sending your form via physical mail, send it as soon as possible and keep any proof of mailing and/or return receipts for your records."];
},
    "evictionfree.emailBodyTemplateForSharingFromConfirmation2": function (a) {
  return ["On December 28, 2020, New York State passed legislation that protects tenants from eviction due to lost income or COVID-19 health risks. In order to get protected, you must fill out a hardship declaration form and send it to your landlord and/or the courts. I just used this website to send a hardship declaration form to my landlord and local courts\u2014putting any eviction case on hold until ", a("date"), ". Check it out here: ", a("url")];
},
    "evictionfree.emailBodyTemplateForSharingFromHomepage1": function (a) {
  return ["On December 28, 2020, New York State passed legislation that protects tenants from eviction due to lost income or COVID-19 health risks. In order to get protected, you must fill out a hardship declaration form and send it to your landlord and/or the courts. You can use this website to send a hardship declaration form to your landlord and local courts\u2014putting your eviction case on hold until ", a("0"), ". Check it out here: ", a("1")];
},
    "evictionfree.erapBlurbOnConfirmation": "You are not alone. Over a million New Yorkers cannot pay rent. New York State now has a rental relief program, the Emergency Rental Assistance Program (ERAP). If you apply for ERAP, <0>your landlord cannot start a new eviction case</0> against you and any current eviction case will be paused.",
    "evictionfree.faqListOfHowToConnectWithLawyer": "<0><1>Call Housing Court Answers at <2>718 557-1379</2> or <3>212 962-4795</3> from Monday - Friday, 9am - 5pm</1><4>Call <5>311</5> and ask for the Tenant Helpline</4><6>Email OCJ (Office of Civil Justice) at <7>civiljustice@hra.nyc.gov</7>. Please provide a name, telephone number, and Housing Court case index number for your eviction case (if known) in your email.</6></0>",
    "evictionfree.faqsPageIntro": "Navigating these laws is confusing. Check out our frequently asked questions from people who have used our tool below. If you have questions about the state of housing court and the current status of eviction cases, check out",
    "evictionfree.financialHardshipExplainer1": "This means you are unable to pay your rent or other financial obligations under the lease in full or obtain alternative suitable permanent housing because of one or more of the following:",
    "evictionfree.financialHardshipExplainer2": "<0>Significant loss of household income during the COVID-19 pandemic.</0><1>Increase in necessary out-of-pocket expenses related to performing essential work or related to health impacts during the COVID-19 pandemic.</1><2>Childcare responsibilities or responsibilities to care for an elderly, disabled, or sick family member during the COVID-19 pandemic have negatively affected your ability or the ability of someone in your household to obtain meaningful employment or earn income or increased your necessary out-of-pocket expenses.</2><3>Moving expenses and difficulty you have securing alternative housing make it a hardship for you to relocate to another residence during the COVID-19 pandemic.</3><4>Other circumstances related to the COVID-19 pandemic have negatively affected your ability to obtain meaningful employment or earn income or have significantly reduced your household income or significantly increased your expenses.</4><5>To the extent that you have lost household income or had increased expenses, any public assistance, including unemployment insurance, pandemic unemployment assistance, disability insurance, or paid family leave, that you have received since the start of the COVID-19 pandemic does not fully make up for your loss of household income or increased expenses.</5>",
    "evictionfree.getInvolvedWithCBO2": "Get involved in your local community organization! Join thousands in the fight for an Eviction Free NY!",
    "evictionfree.hj4aBlurb": "<0>Housing Justice for All</0> is a coalition of over 100 organizations, from Brooklyn to Buffalo, that represent tenants and homeless New Yorkers. We are united in our belief that housing is a human right; that no person should live in fear of an eviction; and that we can end the homelessness crisis in our State.",
    "evictionfree.introToLaw2": "New York State law temporarily protects tenants from eviction due to lost income or COVID-19 health risks. In order to get protected, you must fill out a hardship declaration form and send it to your landlord and/or the courts. Because of landlord attacks, these laws have been weakened. <0>Read your full rights</0> and join the movement to fight back.",
    "evictionfree.introductionToDeclarationFormSteps": "In order to benefit from the eviction protections that local government representatives have put in place, you can notify your landlord by filling out a hardship declaration form. <0>In the event that your landlord tries to evict you, the courts will see this as a proactive step that helps establish your defense.</0>",
    "evictionfree.justfixBlurb1": "<0>JustFix</0> co-designs and builds tools for tenants, housing organizers, and legal advocates fighting displacement in New York City. Our mission is to galvanize a 21st century tenant movement working towards housing for all\u2014and we think the power of data and technology should be accessible to those fighting this fight.",
    "evictionfree.landlordRetaliationWarning": "It\u2019s possible that your landlord will retaliate once they\u2019ve received your declaration. This is illegal. Contact the City's Tenant Helpline (which can provide free advice and legal counsel to tenants) by <0>calling 311</0>",
    "evictionfree.legalAgreementCheckboxOnFees": "I further understand that lawful fees, penalties or interest for not having paid rent in full or met other financial obligations as required by my tenancy, lease agreement or similar contract may still be charged or collected and may result in a monetary judgment against me.",
    "evictionfree.legalAgreementCheckboxOnLandlordChallenge": "I further understand that my landlord may request a hearing to challenge the certification of hardship made herein, and that I will have the opportunity to participate in any proceedings regarding my tenancy.",
    "evictionfree.legalAgreementCheckboxOnNewProtections4": function (a) {
  return ["I further understand that my landlord may be able to seek eviction after ", a("0"), ", and that the law may provide certain protections at that time that are separate from those available through this declaration."];
},
    "evictionfree.outlineOfDeclarationFormSteps": "<0>In the next few steps, we\u2019ll help you fill out your hardship declaration form. Have this information on hand if possible:</0><1><2><3>your phone number and residence</3></2><4><5>your landlord or management company\u2019s mailing and/or email address</5></4></1>",
    "evictionfree.postOfficeFaq": "No, you can use this website to send a letter to your landlord via email or USPS mail. You do not have to pay for the letter to be mailed. If you choose not to use this tool, you will be responsible for mailing your declaration.",
    "evictionfree.printOutFaq": "<0>No, you can print out the <1>hardship declaration form</1> yourself, fill it out by hand, and mail/email it to your landlord and local housing court.</0><2>New York City residents can send their declarations to the court in their borough:</2>",
    "evictionfree.resendFaq": "You currently cannot use this tool to send more than one declaration form. However, once you use this tool, you will be able to download a PDF copy of your declaration on the \u201CConfirmation Page,\u201D and you can choose to resend that declaration on your own. You should keep it for your records, in case your landlord tries to bring you to court.",
    "evictionfree.rtcBlurb": "The <0>Right to Counsel NYC Coalition</0> is a tenant-led, broad-based coalition that formed in 2014 to disrupt Housing Court as a center of displacement and stop the eviction crisis that has threatened our families, our neighborhoods and our homes for too long. Made up of tenants, organizers, advocates, legal services organizations and more, we are building campaigns for an eviction-free NYC and ultimately for a right to housing.",
    "evictionfree.significantHealthRiskExplainer": "This means you or one or more members of your household have an increased risk for severe illness or death from COVID-19 due to being over the age of sixty-five, having a disability or having an underlying medical condition, which may include but is not limited to being immunocompromised.",
    "evictionfree.timeLagFaq": "Once you build your declaration form via this tool, it gets mailed and/or emailed immediately to your landlord and the courts. After it's sent, physical mail usually delivers in about a week.",
    "evictionfree.toolSuspensionMessage": "As of January 15, 2022, tenants in New York State are no longer protected from eviction after submitting a declaration of hardship.",
    "evictionfree.tweetTemplateForSharingFromConfirmation2": function (a) {
  return ["I just used this website to send a hardship declaration form to my landlord and local courts\u2014putting any eviction case on hold until ", a("date"), ". Check it out here: ", a("url"), " #EvictionFreeNY via @JustFixNYC @RTCNYC @housing4allNY"];
},
    "evictionfree.tweetTemplateForSharingFromHomepage1": function (a) {
  return ["You can use this website to send a hardship declaration form to your landlord and local courts\u2014putting your eviction case on hold until ", a("0"), ". Check it out here: ", a("1"), " #EvictionFreeNY via @JustFixNYC @RTCNYC @housing4allNY"];
},
    "evictionfree.whoBuildThisTool": "Our free tool was built by the <0>Right to Counsel NYC Coalition</0>, <1>Housing Justice for All</1>, and <2>JustFix</2> as part of the larger tenant movement across the state.",
    "evictionfree.whoHasRightToSubmitForm": "All tenants in New York State have a right to fill out this hardship declaration form. Especially if you've been served an eviction notice or believe you are at risk of being evicted, please consider using this form to protect yourself.",
    "evictionfree.whoIsNotProtectedFaq": function (a) {
  return ["If a landlord claims that a tenant is a nuisance, meaning they say a tenant \u201Cpersistently behaves in a way that substantially infringes on the use or enjoyment of other tenants OR that causes substantial safety hazards to others,\u201D or that a tenant intentionally caused significant damage to the apartment, then the tenant is not protected by this law. Remember, a landlord would have to show this in court. If they can\u2019t and the tenant filled out the hardship declaration form, then the eviction is delayed until at least ", a("0"), "."];
},
  },
};